import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  modalContent: {
    maxWidth: 580,
  },
  fieldList: {
    display: 'grid',
    gap: spacing(24),
  },
  deleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteButton: {
    padding: spacing(8),
    minWidth: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  label: {
    marginTop: '10px',
    fontSize: Theme.Typography.FontSize.Tiny,
    color: Theme.Colors.Grayscale.ThirtyTwo,
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: props => {
      if (props?.poolAvailable) {
        return props?.multipleTradesman ? '77%' : '85%'
      }

      return props?.multipleTradesman ? '91%' : '85%'
    },
    marginTop: '20px',
    padding: '0px 8px 0 50px',
    flex: props => {
      if (props?.poolAvailable) {
        if (!props?.multipleTradesman) return 1
        return 'unset'
      }
      return props?.multipleTradesman ? 'unset' : 1
    },
    paddingRight: props => {
      if (props?.poolAvailable) {
        return props?.multipleTradesman ? '10px' : '8px'
      }
      return props?.multipleTradesman ? '8px' : 0
    },
  },
  selectWrapperWithPool: {
    width: '85%',
    padding: '0px 28px 0 50px',
  },
  roundLabel: {
    fontSize: `${Theme.Typography.FontSize.Tiny} !important`,
    color: `${Theme.Colors.Grayscale.ThirtyTwo} !important`,
  },
  select: {
    width: '100%',
  },
  selectLabel: {
    fontWeight: '400',
    width: '100%',
  },
  showReferaSuggestions: {
    margin: '0 0 16px',
  },
  runPool: {
    alignSelf: 'flex-end',
  },
}))
